<template>
  <div v-if="state.rows.length === 0" class="flex justify-center items-center p-12">No Data</div>
  <div v-else class="rounded-md shadow-sm overflow-hidden">
    <div class="rounded-md overflow-x-auto">
      <table class="min-w-full">
        <thead class="bg-big-stone bg-opacity-5">
          <tr>
            <th v-for="(heading, index) in state.headings" :key="index" class="px-2 sm:px-4 py-3 text-left text-sm font-bold text-blumine uppercase leading-3">{{ heading }}</th>
          </tr>
        </thead>
        <tbody class="bg-white divide-y divide-gray-200">
          <tr v-for="(columns, index) in state.rows" :key="index" class="hover:bg-gray-50">
            <td class="px-2 sm:px-4 py-1 text-sm whitespace-nowrap" :class="{ 'font-semibold' : index === 0}" v-for="(column, index) in columns" :key="index">{{ column }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { reactive, watch } from 'vue'
import date from '@/helpers/date'
import { sortAlpha } from '@/helpers/utils.js'

export default {
  name: 'ReportsHourlyReportTable',

  props: {
    data: {},
    timeFormat: {
      type: String,
      default: 'MM-DD h:mm A (z)'
    },
    sortByTime: {
      type: String,
      default: 'asc'
    },
    useTimestamp: {
      type: Boolean,
      default: false
    },
    includeLocalTime: {
      type: Boolean,
      default: false
    },
    timezone: {}
  },

  setup(props) {
    const state = reactive({
      headings: [],
      rows: [],
    })

    const extractKeys = (data) => {
      const keys = []

      data.forEach((minute) => minute.items.forEach((item) => ! keys.includes(item.label) ? keys.push(item.label) : null))

      return keys
    }

    const parseDataByHour = (jsonData) => {
      const machineCurrentTime = props.timezone ? date.now().tz(props.timezone) : date.now()
      const data = []
      for (const hour of Array(24).keys()) {
        const machineTime = machineCurrentTime.hour(hour).minute(0)
        const item = jsonData.find((item) => item.hours === hour)
        data.push({
          time: machineTime.format(props.timeFormat),
          local: null,
          hours: hour,
          items: item ? item.items : [],
        })
      }
      return data
    }

    const parseDataByTimestamp = (jsonData) => {
      const machineCurrentTime = props.timezone ? date.now().tz(props.timezone) : date.now()

      const data = []
      for (const hour of Array(24).keys()) {
        const machineTime = machineCurrentTime.subtract(hour, 'hour').minute(0)

        const timestamp = machineTime.format('YYYY-MM-DDTHH')
        const item = jsonData.find((item) => item.timestamp.substring(0, 13) === timestamp)

        let localTime = null
        if (props.includeLocalTime) {
          localTime = date.now().subtract(hour, 'hour').minute(0).format(props.timeFormat)
        }
        data.push({
          time: machineTime.format(props.timeFormat),
          local: localTime,
          hours: hour,
          items: item ? item.items : [],
        })
      }
      return data
    }

    const generateData = (initialData) => {
      state.rows = []

      if (! initialData) {
        return
      }

      const jsonData = JSON.parse(JSON.stringify(initialData))
      const keys = sortAlpha(extractKeys(jsonData))

      const data = props.useTimestamp ? parseDataByTimestamp(jsonData) : parseDataByHour(jsonData)

      if (props.sortByTime === 'asc') {
        data.sort((a, b) => a.hours - b.hours)
      } else {
        data.sort((a, b) => b.hours - a.hours)
      }

      let timeKeys = []
      let headings
      if (props.includeLocalTime) {
        headings = ['Your Time', 'Machine Time', ...keys]
        timeKeys = ['local', 'time']
      }
      else {
        headings = ['Machine Time', ...keys]
        timeKeys = ['time']
      }
      state.headings = headings.map((heading) => {
        const label = heading.replaceAll('-', ' ').replaceAll('_', ' ')
        return label.charAt(0).toUpperCase() + label.slice(1)
      })

      // prepare rows
      data.forEach((hour) => {
        const values = []

        timeKeys.forEach((key) => {
          values.push(hour[key])
        })

        keys.forEach((key) => {
          const item = hour.items.find((i) => i.label === key)
          values.push(item ? item.amount === 0 ? '-' : item.amount : '-')
        })

        state.rows.push(values)
      })
    }

    watch(
      () => props.data,
      () => {
        generateData(props.data)
      }
    )

    generateData(props.data)

    return {
      state,
    }
  }
}
</script>
