<template>
  <div class="px-2 py-4 relative">
    <div v-if="state.graph.data.length === 0" class="flex justify-center items-center p-12">No Data</div>
    <chart v-else type="bubble" :labels="state.graph.labels" :data="state.graph.data" :options="{
      tooltips: {
        mode: 'point',
        callbacks: {
            label: function (tooltipItem, data) {
              return `${data.datasets[tooltipItem.datasetIndex].data.find((item) => item.x === tooltipItem.xLabel).value}x ${data.datasets[tooltipItem.datasetIndex].label || ''} (${parseInt(tooltipItem.label) * -1} ${parseInt(tooltipItem.label) * -1 === 1 ? 'hour' : 'hours'} ago)`
            },
        }
      },
      scaleShowValues: true,
      scales: {
        yAxes: [{
            ticks: {
                callback: generateYAxisLabelForBubbleChart
            }
        }]
      }
    }" />
  </div>
</template>

<script>
import { reactive, watch } from 'vue'
import date from '@/helpers/date'
import Chart from '@/components/charts/Chart.vue'
import { sortAlpha } from '@/helpers/utils.js'

export default {
  name: 'ReportsActivityPerHourChart',

  components: {
    Chart,
  },

  props: {
    data: {},
    modifier: {
      type: Number,
      default: -1,
    },
  },

  setup(props) {
    const state = reactive({
      graph: {
        data: [],
        labels: [],
      },
      keys: [],
    })

    const extractKeys = (data) => {
      const keys = []

      data.forEach((minute) => minute.items.forEach((item) => ! keys.includes(item.label) ? keys.push(item.label) : null))

      return keys
    }

    const generateChartData = (initialData) => {
      state.graph.data = []

      if (! initialData) {
        return
      }

      const data = JSON.parse(JSON.stringify(initialData))
      const keys = state.keys = sortAlpha(extractKeys(data))

      for (const hour of Array(24).keys()) {
        const item = data.find((item) => item.hours === props.modifier * hour)

        if (! item) {
          data.push({
            hours: props.modifier * hour,
            timestamp: date.now().subtract(hour, 'hour').minute(0).toString(),
            items: [],
          })
        }
      }

      data.sort((a, b) => b.hours - a.hours)

      keys.forEach((key, index) => {
        const values = []

        data.forEach((hours) => {
          const item = hours.items.find((i) => i.label === key)
          if (item) {
            const value = Math.log(item.amount) * 10

            values.push({
              x: hours.hours,
              y: index,
              r: value > 35 ? value / 10 : value,
              value: item.amount,
            })
          } else {
            values.push({
              x: hours.hours,
              y: 0,
              r: 0
            })
          }
        })

        state.graph.data.push({
          label: key,
          data: values,
        })
      })

      state.graph.labels = data.map((item) => item.hours)
    }

    const generateYAxisLabelForBubbleChart = (value) => {
      return state.keys[value]
    }

    watch(
      () => props.data,
      () => {
        generateChartData(props.data)
      }
    )

    generateChartData(props.data)

    return {
      state,
      generateYAxisLabelForBubbleChart,
    }
  }
}
</script>
