<template>
  <div class="flex flex-col md:flex-row md:justify-between md:items-center space-y-4 md:space-y-0 divide-y divide-gray-200 md:divide-y-0 md:py-4 md:border-b md:border-gray-200  bg-gray-50 mb-4 md:mb-6">
    <div class="w-full md:max-w-lg md:justify-end md:order-2 flex flex-row space-x-2 justify-between">
      <div class="bg-gray-50 rounded py-0.5 px-1 md:px-2 flex flex-row">
        <div class="md:flex-grow">
          <div v-if="!realtime" class="flex flex-row md:flex-row-reverse items-center h-full">
            <button
              class="md:ml-4 mr-1 text-blumine hover:text-big-stone"
              title="Back to Realtime"
              @click="navigateToRealtime"
            >
              <span class="hidden md:block">
                <icon-x class="w-5 h-5" />
              </span>
              <span class="md:hidden flex flex-row items-center group px-0.5">
                <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"></path></svg>
                <span class="group-hover:underline pb-0.5 pl-0.5">Realtime</span>
              </span>
            </button>

            <div class="text-sm hidden md:block text-right leading-none text-big-stone">
              from: <span class="ml-1 font-semibold">{{ route.query.from }}</span><br>
              to: <span class="ml-1 font-semibold">{{ route.query.to }}</span>
            </div>
          </div>
        </div>

        <button
          class="focus:outline-none text-blumine hover:text-big-stone pl-2 md:pl-0"
          @click="openDateRangeModal"
        >
          <icon-date-range class="w-6 h-6" />
        </button>
      </div>

      <select-box
        class="w-auto"
        :model-value="activeMachine.location_id"
        @change="(id) => navigateToMachine(id)"
      >
        <option
          v-for="machine in machines"
          :key="machine.location_id"
          :value="machine.location_id"
        >
          {{ machine.name }}
        </option>
      </select-box>
    </div>

    <h2 class="text-lg font-bold md:order-1 pt-2 md:pt-0">
      {{ activeMachine.name }}
    </h2>
  </div>
</template>

<script>
import { useRoute, useRouter } from 'vue-router'

import { IconDateRange } from '@/components/icons'
import SelectBox from '@/components/form/Select.vue'
import { IconX } from '@/components/icons'

export default {
  name: 'ReportsFilterHeader',

  components: {
    IconDateRange,
    SelectBox,
    IconX,
  },

  props: [
    'activeMachine',
    'isRangeModalOpen',
    'machines',
    'realtime'
  ],

  emits: [
    'update:isRangeModalOpen',
  ],

  setup(props, { emit }) {
    const router = useRouter()
    const route = useRoute()

    const navigateToMachine = (id) => {
      const path = {
        name: route.name,
        params: { machineId: parseInt(id) }
      }
      if (!props.realtime) {
        path.query = {
          from: route.query.from,
          to: route.query.to
        }
      }
      router.push(path)
    }

    const navigateToRealtime = () => {
      router.push({
        name: 'ReportsRealTime',
        params: { machineId: props.activeMachine.location_id }
      })
    }

    const openDateRangeModal = () => {
      emit('update:isRangeModalOpen', true)
    }

    return {
      route,
      openDateRangeModal,
      navigateToMachine,
      navigateToRealtime
    }
  },
}
</script>