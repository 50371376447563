<template>
  <div class="flex flex-col rounded-md shadow-sm bg-white text-big-stone border-t-8 border-blumine">
    <div class="flex flex-col items-center justify-center space-y-1 p-4 h-24">
      <div class="font-bold text-3xl lg:text-4xl xxl:text-5xl leading-none lg:leading-none">
        {{ data.ice_vends }}
      </div>
      <div class="text-sm font-semibold">Credited Vends</div>
    </div>
    <div class="flex flex-row px-4 py-3 border-t divide-x divide-gray-200">
      <div class="w-1/3 text-center">
        <div class="font-bold text-2xl lg:text-3xl leading-none lg:leading-none">{{ bagPercentage }}%</div>
        <div class="text-sm font-medium">Bag: <span class="text-base font-semibold">{{ data.bag }}</span></div>
      </div>
      <div class="w-1/3 text-center">
        <div class="font-bold text-2xl lg:text-3xl leading-none lg:leading-none">{{ bulkPercentage }}%</div>
        <div class="text-sm font-medium">Bulk: <span class="text-base font-semibold">{{ data.bulk }}</span></div>
      </div>
      <div class="w-1/3 text-center">
        <div class="font-bold text-2xl lg:text-3xl leading-none lg:leading-none">{{ freePercentage }}%</div>
        <div class="text-sm font-medium">Free: <span class="text-base font-semibold">{{ data.free }}</span></div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/runtime-core'

export default {
  name: 'ReportsMachineIceVends',

  props: ['data'],

  setup(props) {
    const bagPercentage = computed(() => {
      if (! props.data.ice_vends) {
        return 0
      }

      return Math.round(props.data.bag / props.data.ice_vends * 100)
    })

    const bulkPercentage = computed(() => {
      if (! props.data.ice_vends) {
        return 0
      }

      return Math.round(props.data.bulk / props.data.ice_vends * 100)
    })

    const freePercentage = computed(() => {
      if (! props.data.ice_vends) {
        return 0
      }

      return Math.round(props.data.free / props.data.ice_vends * 100)
    })

    return {
      bagPercentage,
      bulkPercentage,
      freePercentage,
    }
  }
}
</script>
