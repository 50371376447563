<template>
  <modal :is-modal-open="isRangeModalOpen" @close="close()">
    <h3 class="text-lg font-bold -mt-2">Select Date Range</h3>
    <div class="block absolute top-0 right-0 z-10">
      <button @click="emitModalClose" type="button" class="text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150 p-4" aria-label="Close">
        <icon-x class="w-5 h-5" />
      </button>
    </div>
    <form @submit.prevent="submitForm" class="flex flex-wrap items-center mt-3" novalidate>
      <date-range-picker v-model="state.range" :with-range-selector="false" :max-range="30" />

      <div class="flex space-x-2 mt-4">
        <Button type="submit" v-if="state.range.from && state.range.to">
          View
        </Button>
      </div>
    </form>
  </modal>
</template>

<script>

import { reactive } from 'vue'

import Button from '@/components/form/Button.vue'
import DateRangePicker from '@/components/form/DateRange.vue'
import { IconX } from '@/components/icons'
import Modal from '@/components/Modal.vue'

export default {
  name: 'modal-date-range',

  components: {
    Button,
    DateRangePicker,
    IconX,
    Modal,
  },

  props: ['isRangeModalOpen', 'range'],

  emits: [
    'onSubmit',
    'update:isRangeModalOpen',
  ],

  setup(props, { emit }) {
    const state = reactive({
      range: props.range || {},
    })

    const emitModalClose = () => {
      emit('update:isRangeModalOpen', false)
    }

    const submitForm = () => {
      emit('onSubmit', state.range)
    }

    return {
      state,
      emitModalClose,
      submitForm
    }
  }
}
</script>
