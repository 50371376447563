<template>
  <div class="flex flex-col rounded-md shadow-sm bg-white text-big-stone border-t-8 border-shakespear">
    <div class="flex flex-col items-center justify-center space-y-1 p-4 h-24">
      <div class="font-bold text-3xl lg:text-4xl xxl:text-5xl leading-none lg:leading-none">
        {{ data.water_vends }}
      </div>
      <div class="text-sm font-semibold">Credited Vends</div>
    </div>
    <div class="flex flex-row px-4 py-3 border-t divide-x divide-gray-200">
      <div class="w-1/3 text-center">
        <div class="font-bold text-2xl lg:text-3xl leading-none lg:leading-none">{{ gal1Percentage }}%</div>
        <div class="text-sm font-medium">Gallon 1: <span class="text-base font-semibold">{{ data.gal1 }}</span></div>
      </div>
      <div class="w-1/3 text-center">
        <div class="font-bold text-2xl lg:text-3xl leading-none lg:leading-none">{{ gal3Percentage }}%</div>
        <div class="text-sm font-medium">Gallon 3: <span class="text-base font-semibold">{{ data.gal3 }}</span></div>
      </div>
      <div class="w-1/3 text-center">
        <div class="font-bold text-2xl lg:text-3xl leading-none lg:leading-none">{{ gal5Percentage }}%</div>
        <div class="text-sm font-medium">Gallon 5: <span class="text-base font-semibold">{{ data.gal5 }}</span></div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/runtime-core'

export default {
  name: 'ReportsMachineWaterVends',

  props: ['data'],

  setup(props) {
    const gal1Percentage = computed(() => {
      if (! props.data.water_vends) {
        return 0
      }

      return Math.round(props.data.gal1 / props.data.water_vends * 100)
    })

    const gal3Percentage = computed(() => {
      if (! props.data.water_vends) {
        return 0
      }

      return Math.round(props.data.gal3 / props.data.water_vends * 100)
    })

    const gal5Percentage = computed(() => {
      if (! props.data.water_vends) {
        return 0
      }

      return Math.round(props.data.gal5 / props.data.water_vends * 100)
    })

    return {
      gal1Percentage,
      gal3Percentage,
      gal5Percentage,
    }
  }
}
</script>
